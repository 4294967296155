<template>
  <div>
   <CommunitySet type="name"></CommunitySet>
  </div>
</template>

<script>
import CommunitySet from './components/CommunitySet.vue'
export default {
  name: 'SetName',
  components: { CommunitySet },
  data () {
    return {}
  },
  methods: {},
  mounted () {}
}
</script>

<style scoped lang="scss">
</style>
